import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../utils/api";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";

const ErrorModal = ({ message, onClose }) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-4 shadow-md max-w-sm w-full">
        <div className="text-center mb-4">
          <h2 className="text-lg font-semibold text-red-600">Error</h2>
        </div>
        <p className="text-sm text-gray-700 text-center">{message}</p>
        <div className="mt-4 text-center">
          <button onClick={onClose} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    userType: "",
    email: "",
    password: "",
    confirmPassword: "",
    state: "",
    cities: [],
  });

  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [locationsData, setLocationsData] = useState([]);

  const getFieldLabel = useCallback((fieldName) => {
    const fieldLabels = {
      fname: "First Name",
      lname: "Last Name",
      userType: "User Type",
      confirmPassword: "Confirm Password",
      email: "Email",
      password: "Password",
      state: "State",
      cities: "Cities",
    };
    return fieldLabels[fieldName] || fieldName;
  }, []);

  const fetchUserDetails = useCallback(async () => {
    try {
      const res = await api.get(`/user/getById/${id}`);
      const { fname, lname, userType, email, state, cities } = res.data.response;
      setFormData({
        fname,
        lname,
        userType,
        email,
        state,
        cities,
        password: "",
        confirmPassword: "",
      });
      setSelectedCities(cities.map((city) => ({ value: city, label: city })));
      if (locationsData.length) fetchCities(state);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setErrorMessage("Failed to fetch user details. Please try again later.");
      setErrorModalOpen(true);
    }
  }, [id]);

  const fetchLocations = useCallback(async () => {
    try {
      const res = await api.get("/user/locations");
      const response = res.data.response;
      setLocationsData(response);
      setStateOptions(response.map((location) => ({ value: location.state, label: location.state })));
    } catch (error) {
      console.error("Error fetching locations:", error);
      setErrorMessage("Failed to fetch locations. Please try again later.");
      setErrorModalOpen(true);
    }
  }, []);

  const fetchCities = useCallback(
    (state) => {
      const location = locationsData.find((loc) => loc.state === state);
      setCityOptions(location ? location.cities.map((city) => ({ value: city, label: city })) : []);
    },
    [locationsData]
  );

  useEffect(() => {
    fetchLocations();
    fetchUserDetails();
  }, [fetchLocations, fetchUserDetails]);

  useEffect(() => {
    if (locationsData.length) fetchCities(formData.state);
  }, [locationsData, formData.state, fetchCities]);

  const handleStateChange = useCallback(
    (selectedOption) => {
      const state = selectedOption ? selectedOption.value : "";
      setFormData((prevFormData) => ({ ...prevFormData, state, cities: [] }));
      setSelectedCities([]);
      fetchCities(state);
      setIsSaveEnabled(true);
    },
    [fetchCities]
  );

  const handleCityChange = useCallback((selectedOptions) => {
    const cities = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    setSelectedCities(selectedOptions);
    setFormData((prevFormData) => ({ ...prevFormData, cities }));
    setIsSaveEnabled(true);
    checkErrors("cities", cities.join(""));
  }, []);

  const checkErrors = useCallback(
    (name, value) => {
      const optional = ["password", "confirmPassword"];
      if (!value && !optional.includes(name)) {
        setErrors((prev) => ({ ...prev, [name]: `${getFieldLabel(name)} is required` }));
      } else {
        switch (name) {
          case "email":
            setErrors((prev) => ({
              ...prev,
              [name]: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.toLowerCase()) ? null : "Please enter a valid email address.",
            }));
            break;
          case "password":
            setErrors((prev) => ({
              ...prev,
              [name]: value && value.length < 8 ? "Password should be Min 8 characters." : null,
              confirmPassword: formData.confirmPassword !== value ? "Not matched with a password." : null,
            }));
            break;
          case "confirmPassword":
            setErrors((prev) => ({
              ...prev,
              [name]: formData.password !== value ? "Not matched with a password." : null,
            }));
            break;
          default:
            setErrors((prev) => ({ ...prev, [name]: null }));
        }
      }
    },
    [getFieldLabel, formData.password, formData.confirmPassword]
  );

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
      checkErrors(name, value);
      setIsSaveEnabled(true);
    },
    [checkErrors]
  );

  const validate = useCallback(
    (event) => {
      const { name, value } = event.target;
      checkErrors(name, value);
    },
    [checkErrors]
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = {};
    const requiredFields = ["fname", "lname", "userType", "email", "state", "cities"];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        validationErrors[field] = `${getFieldLabel(field)} is required`;
      }
    });

    if (!selectedCities.length) {
      validationErrors.cities = "City is required";
    }

    if (formData.email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!re.test(String(formData.email).toLowerCase())) validationErrors.email = "Please enter a valid email address.";
    }

    if (formData.password && formData.password.length < 8) {
      validationErrors.password = "Password should be Min 8 characters.";
    }

    if (formData.password && formData.confirmPassword && formData.password !== formData.confirmPassword) {
      validationErrors.confirmPassword = "Not matched with a password.";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    try {
      await api.put(`/user/update/${id}`, { email: formData.email.toLowerCase(), ...formData });

      navigate("/user");
    } catch (error) {
      console.error("Error updating user:", error);
      setErrorMessage("This Email ID exists.");
      setErrorModalOpen(true);
    }
  };

  const closeErrorModal = () => {
    setErrorModalOpen(false);
    setErrorMessage("");
  };

  return (
    <div className="bg-emerald-50 min-h-screen flex flex-col">
      <div className="container mx-auto px-4 py-8 bg-emerald-50">
        {errorModalOpen && <ErrorModal message={errorMessage} onClose={closeErrorModal} />}
        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-md p-8 mx-4 md:mx-16">
          <h2 className="text-2xl font-bold mb-4 text-center">Edit USER</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="mb-4">
              <label htmlFor="fname" className="block text-sm font-medium text-gray-700">
                First Name *
              </label>
              <input
                type="text"
                id="fname"
                name="fname"
                onBlur={validate}
                value={formData.fname}
                onChange={handleChange}
                className={`mt-1 block w-full border border-gray-500 p-2 rounded-md shadow-sm focus:border-indigo-500 sm:text-sm ${errors.fname ? "border-red-500" : ""}`}
              />
              {errors.fname && <p className="text-red-500 text-sm mt-1">{errors.fname}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="lname" className="block text-sm font-medium text-gray-700">
                Last Name *
              </label>
              <input
                type="text"
                id="lname"
                name="lname"
                onBlur={validate}
                value={formData.lname}
                onChange={handleChange}
                className={`mt-1 block w-full border border-gray-500 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.lname ? "border-red-500" : ""}`}
              />
              {errors.lname && <p className="text-red-500 text-sm mt-1">{errors.lname}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="userType" className="block text-sm font-medium text-gray-700">
                User Type *
              </label>
              <select
                id="userType"
                name="userType"
                onBlur={validate}
                value={formData.userType}
                onChange={handleChange}
                className={`mt-1 block w-full border border-gray-500 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.userType ? "border-red-500" : ""}`}>
                <option value="" disabled>
                  Select User Type
                </option>
                <option value="admin">Admin</option>
                <option value="collector">Collector</option>
              </select>
              {errors.userType && <p className="text-red-500 text-sm mt-1">{errors.userType}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email *
              </label>
              <input
                type="text"
                id="email"
                name="email"
                onBlur={validate}
                value={formData.email}
                onChange={handleChange}
                className={`mt-1 block w-full border border-gray-500 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.email ? "border-red-500" : ""}`}
              />
              {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                onBlur={validate}
                value={formData.password}
                onChange={handleChange}
                className={`mt-1 block w-full border border-gray-500 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.password ? "border-red-500" : ""}`}
              />
              {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                onBlur={validate}
                value={formData.confirmPassword}
                onChange={handleChange}
                className={`mt-1 block w-full border border-gray-500 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                  errors.confirmPassword ? "border-red-500" : ""
                }`}
              />
              {errors.confirmPassword && <p className="text-red-500 text-sm mt-1">{errors.confirmPassword}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                State *
              </label>
              <Select
                id="state"
                options={stateOptions}
                onChange={handleStateChange}
                value={stateOptions.find((option) => option.value === formData.state)}
                className={`basic-single mt-1 border border-gray-500 rounded-md ${errors.state ? "border-red-500" : ""}`}
                placeholder="Select State"
              />
              {errors.state && <p className="text-red-500 text-sm mt-1">{errors.state}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="cities" className="block text-sm font-medium text-gray-700">
                Cities *
              </label>
              <MultiSelect
                id="cities"
                options={cityOptions}
                value={selectedCities}
                onChange={handleCityChange}
                labelledBy="Select Cities"
                disabled={!formData.state}
                className={`basic-multi-select mt-1 border border-gray-500 rounded-md shadow-sm ${errors.cities ? "border-red-500" : ""}  ${formData.state ? "" : "cursor-not-allowed opacity-50"}`}
              />
              {errors.cities && <p className="text-red-500 text-sm mt-1">{errors.cities}</p>}
            </div>
          </div>
          <div className="flex justify-between">
            <button
              type="submit"
              className={`px-4 py-2 rounded-md text-white ${!Object.values(errors).some((e) => e) && isSaveEnabled ? "bg-color " : "bg-gray-400 cursor-not-allowed"}`}
              disabled={Object.values(errors).some((e) => e) || !isSaveEnabled}>
              Save
            </button>
            <button type="button" onClick={() => navigate("/user")} className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500 transition-colors duration-300">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUser;
